<template>
  <div class="w-full">
    <div class="flex flex-col sm:flex-row gap-4 items-center mb-12">
      <Title title="Wallet" size="large" />

      <Button
        class="ml-auto"
        kind="primary"
        label="Request withdraw"
        :grow="windowWidth < 640 ? true : false"
        @clicked="requestWithdraw"
      />
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8 mb-16 w-full">
      <KPI
        label="Available for withdraw"
        :value="`€${wallet && wallet.withdrawable ? wallet.withdrawable.toFixed(1) : 0}`"
      />

      <KPI
        label="Locked pending campaign completion"
        :value="`€${wallet && wallet.locked ? wallet.locked.toFixed(1) : 0}`"
      />

      <KPI label="Earned today" :value="`€${wallet && wallet.today ? wallet.today.toFixed(1) : 0}`" />

      <KPI label="Earned this week" :value="`€${wallet && wallet.week ? wallet.week.toFixed(1) : 0}`" />

      <KPI label="Earned this month" :value="`€${wallet && wallet.month ? wallet.month.toFixed(1) : 0}`" />

      <KPI label="Earned this year" :value="`€${wallet && wallet.year ? wallet.year.toFixed(1) : 0}`" />

      <KPI label="Total earned" :value="`€${wallet && wallet.totalEarned ? wallet.totalEarned.toFixed(1) : 0}`" />
    </div>

    <div v-if="false">
      <div class="flex items-center mb-4 w-full">
        <Title title="Withdraw history" size="medium" />
      </div>

      <div>
        <div v-if="withdrawHistory"></div>

        <p v-else>No withdraws yet</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { KPI, Title, Button } from "cavea-design-system";
import log from "@/lib/log";

export default {
  name: "StreamerWallet",

  metaInfo: {
    title: "Wallet",
  },

  components: {
    Title,
    Button,
    KPI,
  },

  data() {
    return {
      windowWidth: 1920,

      withdrawHistory: null,
      wallet: {
        locked: 0,
        withdrawable: 0,
        totalEarned: 0,
        year: 0,
        month: 0,
        week: 0,
        today: 0,
      },
    };
  },

  computed: {
    ...mapGetters(["getOrganization"]),
  },

  watch: {
    getOrganization: {
      handler() {
        if (this.getOrganization?._id) {
          this.getWallet();
        }
      },
    },
  },

  created() {
    this.windowWidth = window?.innerWidth || 1920;

    window.addEventListener("resize", () => {
      this.windowWidth = window?.innerWidth || 1920;
    });

    if (this.getOrganization?._id) {
      this.getWallet();
    }
  },

  methods: {
    async getWallet() {
      log("getwallet");
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/organization/wallet/${this.getOrganization?._id}`;

      this.wallet = await axios
        .get(URL)
        .then((res) => res.data.wallet)
        .catch((err) => {
          console.error("getWallet error", err);

          return {
            locked: 0,
            withdrawable: 0, // TODO: Make some kind of system to find out how much user has withdrawn
            totalEarned: 0,
            year: 0,
            month: 0,
            week: 0,
            today: 0,
          };
        });
    },

    requestWithdraw() {
      this.$swal({
        icon: "info",
        title: "Request withdraw",
        text: "We are working on implementing an automatic withdraw system. In the meantime, please send an email to jg@adlab.gg with the title 'Withdraw request' if you wish to withdraw.",
      });
    },
  },
};
</script>
